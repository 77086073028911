module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GLCMJJWYVT"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vai Gia Xuong","short_name":"vaigiaxuong","start_url":"/","background_color":"#4DB58E","theme_color":"#4DB58E","display":"minimal-ui","icon":"src/images/logocty.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b0a2a710fcb7cf3a77ec8df9bc85a5ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-identity/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://trusting-sammet-a20adf.netlify.app/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
